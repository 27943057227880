/**
* Table of Contents
 * 1.0 - Fonts
 * 2.0 - Reset css
 * 3.0 - Common 
 * 4.0 - Theme color/button styles
 * 5.0 - section
 * 6.0 - footer
 * 7.0 -Responsive
/* ==========================================================================
   1.0 Fonts
   ========================================================================== */

/* ==========================================================================
   2.0 Reset css
   ========================================================================== */
html,
body {
  overflow-x: hidden;
  font-size: 16px;
  color: #000;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  white-space: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #000;
  line-height: 1.2;
  margin: 0 0 5px 0;
}
h1 {
  font-size: 4em;
}
h2 {
  font-size: 3.5em;
}
h3 {
  font-size: 3em;
}
h4 {
  font-size: 2.5em;
}
h5 {
  font-size: 2em;
}
h6 {
  font-size: 1.5em;
}
ul {
  padding: 0px;
}
li {
  list-style: none;
  margin-bottom: 10px;
}

a {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  text-decoration: none;
  outline: 0;
  color: inherit;
}

.nav > li > a {
  padding: 0;
}
a:active,
a:focus {
  outline: 0;
  -moz-outline: 0;
  text-decoration: none;
}

button:focus,
input:focus,
a:focus,
*:focus {
  outline: 0;
  text-decoration: none;
  outline-offset: 0px;
}
img {
  max-width: 100%;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
/* ==========================================================================
   3.0 Common css
   ========================================================================== */
.mainHeading {
  font-size: 2.5rem;
  line-height: 2.8rem;
  margin: 20px 0px;
}
/* ==========================================================================
  4.0 theme css
   ========================================================================== */
.greyBg {
  background: #f2f2f2;
}
section {
  padding: 40px 0px;
}
.boxLayout {
  border-radius: 10px;

  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0px 15px 20px;
  margin-bottom: 20px;
}
.sectionHeading {
  background: #fff;
  margin: 0px -15px 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px 10px 0px 0px;
}

/* ==========================================================================
   5.0 sectiom css
   ========================================================================== */
.collapseForm {
  margin-bottom: 20px;
}
.collapseForm .card-header {
  background: #fff;
  padding: 5px 10px;
}
.collapseForm .card-body {
  background: #f8f8f8;
  padding-bottom: 10px;
}
.btn-link {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  padding-left: 5px;
}
.btn-link:hover {
  text-decoration: none;
  color: #7a90a0;
}
.btn-link:focus {
  text-decoration: none;
}
.bottomText {
  margin-top: 20px;
}
.bottomText p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}
.bottomText img {
  width: 20px;
  margin-left: 10px;
}
.collapseForm .card {
  border-radius: 10px;
}
.form-control {
  margin-bottom: 10px;
}
.orderSummary {
}
.productListing li {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.productListing li:last-child {
  border: none;
}
.productListing .productImage {
  width: 30%;
  background: #fff;
  padding: 5px;
  height: 80px;
  text-align: center;
  margin-right: 20px;
}
.productListing .productText {
  width: 70%;
}
.productListing .productImage img {
  width: auto;
  height: 100%;
}
.productListing .productText h6 {
  font-size: 15px;
}
.productListing .productText .cost {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
.productListing .productText .quantity {
  font-size: 13px;
}
.cartSummary p {
  font-size: 13px;
  margin-bottom: 10px;
}
.cartSummary p a {
  color: #7a90a0;
  padding: 0px 5px;
  font-weight: 500;
}
.cartSummary p i {
  padding-left: 5px;
}
.tabularData p {
  font-size: 14px;
}
.tabularData strong,
.tabularData span {
  width: 50%;
  display: inline-block;
}
.largeText {
  font-size: 16px;
  font-weight: bold;
}

.thanksText {
  padding: 3rem 0px;
  text-align: center;
}
.thanksText h4 {
  font-size: 2rem;
}
.thanksText h6 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

/* track order */
.ga-trackOrderInfo .app-dwn-btns a img {
  max-width: 200px;
}
.ga-trackOrderInfo .app-dwn-btns a + a {
  margin-left: 15px;
}

.ga-trackOrderInfo .app-dwn-qr a img {
  max-width: 200px;
}
.ga-trackOrderInfo .app-dwn-qr a + a {
  margin-left: 15px;
}
.ga-head {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}
.ga-subhead {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

/* order summary */
.orderSummary ul.productListing {
  /* height: 330px;
  overflow-y: scroll;*/
}
.ga-submit button {
  background: #beb8f8;
  border-radius: 30px;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0 14px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.ga-submit a {
  background: #beb8f8;
  border-radius: 30px;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0 14px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

/*new changes */
.invalid-input {
  font-size: 14px;
  color: red;
  margin-bottom: 10px;
  margin-top: -5px;
  margin-left: 5px;
}
.mandatory:after {
  content: "*";
  color: red;
  position: absolute;
  top: 0px;
  right: 22px;
}
.mandatory {
  position: relative;
}
.scroll {
  overflow-y: scroll;
  height: 300px;
}

.loader {
  position: absolute;
  z-index: 100;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.cancel {
  display: flex;
}
.events ul {
  width: auto;
}
.events li {
  display: flex;
  color: #999;
}

.events time {
  position: relative;
  padding: 0 1.5em;
  color: transparent;
}

.events time::after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  /* border-radius: 50%;
  background: #fff;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em; */
}

.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
  color: #000;
  margin-bottom: 5px;
  margin-top: -2px;
  z-index: 9;
}

.scooter {
  position: absolute;
  left: -10px;
  z-index: 9999;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  /* border-left: 1px #ccc solid; */
  border-style: dashed;
  border-width: 1px;
}

.events > li:last-child span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  height: 50%;
  /* border-left: 1px #ccc solid; */
}

.events .active {
  color: #685e93;
  /* border-left: 1px #685e93; */
  margin-top: -6px;
}

.events .active::before {
  border-style: solid;
  border-width: 1px;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
  /* width: 50%; */
}
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}

.marker {
  position: absolute;
  left: -10px;
  top: -14px;
  font-size: 15px;
}
.marker2 {
  left: -10px;
  position: absolute;
  top: 22px;
  font-size: 15px;
}

.sc-chat-window {
  z-index: 9999999;
}

.sc-user-input--file-icon {
  display: none;
}

.sc-chat-window > .sc-header {
  /* background-color: #beb8f8; */
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
